import React from "react"
import Image from "../images/jam-jar-pink.svg"
import Join from "../components/join"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Shop = () => (
  <Layout>
    <Seo title="Shop" />

    <header>
      <img
        src={Image}
        width={217}
        height={260}
        alt="restful coder logo"
      />
    </header>

    <br />
    <br />

    <section>
      <h2>Shop</h2>

      <p>At the moment I'm building out the shop. If there's interest, I may include a list of recommended books and talks until I can provide some of my own. I believe that you'll learn the most when you're open to books and talks that aren't about your specific programming language or industry, so don't be shy to check out the books or talks for other languages.</p>

      <h2>Books</h2>

      <ul>
        <li><a target="_blank" rel="noreferrer" href="https://sandimetz.com/99bottles">99 Bottles, Sandi Metz</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://www.amazon.com/gp/product/B07F88LY9M">Practical Object-Oriented Design, Sandi Metz</a></li>
        <li><a target="_blank" rel="noreferrer" href="https://www.amazon.com/dp/B07LCM8RG2">Refactoring, Martin Fowler</a></li>
      </ul>

      <h2>Guides</h2>

      <p>Coming soon.</p>
    </section>

    <Join />
  </Layout>
)

export default Shop
